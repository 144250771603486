var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content" }, [
    _c(
      "div",
      { staticClass: "table-content row-data" },
      [
        _c(
          "el-form",
          {
            ref: "responseData",
            attrs: {
              "label-width": "200px",
              model: _vm.responseData,
              "label-position": "left",
            },
          },
          [
            _c(
              "el-col",
              { attrs: { span: 12 } },
              [
                _c("span", [_vm._v("Phụ huynh")]),
                _c(
                  "el-form-item",
                  { attrs: { label: "Hiển thị điểm danh ngày" } },
                  [
                    _c("el-checkbox", {
                      model: {
                        value: _vm.responseData.showAttendanceDateParent,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.responseData,
                            "showAttendanceDateParent",
                            $$v
                          )
                        },
                        expression: "responseData.showAttendanceDateParent",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "Hiển thị điểm danh tháng" } },
                  [
                    _c("el-checkbox", {
                      model: {
                        value: _vm.responseData.showAttendanceMonthParent,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.responseData,
                            "showAttendanceMonthParent",
                            $$v
                          )
                        },
                        expression: "responseData.showAttendanceMonthParent",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "Hiển thị điểm danh ăn" } },
                  [
                    _c("el-checkbox", {
                      model: {
                        value: _vm.responseData.showAttendanceEatParent,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.responseData,
                            "showAttendanceEatParent",
                            $$v
                          )
                        },
                        expression: "responseData.showAttendanceEatParent",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-col",
              { attrs: { span: 12 } },
              [
                _c("span", [_vm._v("Giáo viên/Chủ trường")]),
                _c(
                  "el-form-item",
                  { attrs: { label: "Hiển thị điểm danh đến" } },
                  [
                    _c("el-checkbox", {
                      model: {
                        value: _vm.responseData.showAttendanceArriveTeacherPlus,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.responseData,
                            "showAttendanceArriveTeacherPlus",
                            $$v
                          )
                        },
                        expression:
                          "responseData.showAttendanceArriveTeacherPlus",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "Hiển thị điểm danh về" } },
                  [
                    _c("el-checkbox", {
                      model: {
                        value: _vm.responseData.showAttendanceLeaveTeacherPlus,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.responseData,
                            "showAttendanceLeaveTeacherPlus",
                            $$v
                          )
                        },
                        expression:
                          "responseData.showAttendanceLeaveTeacherPlus",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "Hiển thị điểm danh ăn" } },
                  [
                    _c("el-checkbox", {
                      model: {
                        value: _vm.responseData.showAttendanceEatTeacherPlus,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.responseData,
                            "showAttendanceEatTeacherPlus",
                            $$v
                          )
                        },
                        expression: "responseData.showAttendanceEatTeacherPlus",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "Hiển thị nhận xét học tập" } },
                  [
                    _c("el-checkbox", {
                      model: {
                        value: _vm.responseData.showEvaluateLearnTeacherPlus,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.responseData,
                            "showEvaluateLearnTeacherPlus",
                            $$v
                          )
                        },
                        expression: "responseData.showEvaluateLearnTeacherPlus",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "Hiển thị nhận xét ăn uống" } },
                  [
                    _c("el-checkbox", {
                      model: {
                        value: _vm.responseData.showEvaluateEatTeacherPlus,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.responseData,
                            "showEvaluateEatTeacherPlus",
                            $$v
                          )
                        },
                        expression: "responseData.showEvaluateEatTeacherPlus",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "Hiển thị nhận xét ngủ nghỉ" } },
                  [
                    _c("el-checkbox", {
                      model: {
                        value: _vm.responseData.showEvaluateSleepTeacherPlus,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.responseData,
                            "showEvaluateSleepTeacherPlus",
                            $$v
                          )
                        },
                        expression: "responseData.showEvaluateSleepTeacherPlus",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "Hiển thị nhận xét vệ sinh" } },
                  [
                    _c("el-checkbox", {
                      model: {
                        value: _vm.responseData.showEvaluateSanitaryTeacherPlus,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.responseData,
                            "showEvaluateSanitaryTeacherPlus",
                            $$v
                          )
                        },
                        expression:
                          "responseData.showEvaluateSanitaryTeacherPlus",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "Hiển thị nhận xét sức khỏe" } },
                  [
                    _c("el-checkbox", {
                      model: {
                        value: _vm.responseData.showEvaluateHealthTeacherPlus,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.responseData,
                            "showEvaluateHealthTeacherPlus",
                            $$v
                          )
                        },
                        expression:
                          "responseData.showEvaluateHealthTeacherPlus",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "Hiển thị nhận xét chung" } },
                  [
                    _c("el-checkbox", {
                      model: {
                        value: _vm.responseData.showEvaluateCommonTeacherPlus,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.responseData,
                            "showEvaluateCommonTeacherPlus",
                            $$v
                          )
                        },
                        expression:
                          "responseData.showEvaluateCommonTeacherPlus",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-button",
          {
            staticStyle: { float: "right" },
            attrs: {
              type: "success",
              size: "medium",
              loading: _vm.loadingButton,
            },
            on: {
              click: function ($event) {
                return _vm.submitForm()
              },
            },
          },
          [_vm._v("Lưu")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }